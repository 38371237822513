import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../../components/Layout';
import useWindowSize from '../../../helpers/useWindowSize';

const HundreDikt = ({ data }) => {
  const [width, height] = useWindowSize();
  return (
    <Layout>
      <section className='hundredikt'>
        <h1>hundre dikt om fred</h1>
        <h2>
          <Link to='/work'>2019/design</Link>
        </h2>

        <Img
          fluid={data.image1.childImageSharp.fluid}
          alt='3 hundre dikt om fred books'
          className='topimage'
        />

        <div className='mid-section'>
          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt='hundre dikt om fred book open showing back'
            className='leftimage'
          />
          <p className='maintext'>
            This concept was created to draw more visitors to the nobel peace
            center.
            <br />
            <br />
            The book is a compilation of hundreds poems and hundred
            illustrations by a hundred writers and a hundred illustrators to
            celebrate the 100th peace prize. Showcasing the different
            interpretations of peace, and the meaning it has to different
            people.
            <br />
            <br />
            The contributors consists of Norwegian writers, comedians,
            musicians, painters, illustrators and more.
          </p>
          <p className='extratext'>
            It also includes 10 submitted contributions from visitors of the
            center.
            <br />
            Who will be able to submit a text or illustration when visiting.
          </p>

          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt='hundre dikt om fred open showing 2 pages'
            className='rightimage'
            style={{
              position: width > 1100 ? 'absolute' : 'relative',
            }}
          />
        </div>

        <Img
          fluid={data.image4.childImageSharp.fluid}
          alt='hundre dikt om freed book open showing 2 pages'
          className='bottomimage'
        />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query hundreimages {
    image1: file(relativePath: { eq: "100dikt/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "100dikt/2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "100dikt/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "100dikt/4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default HundreDikt;
